import { Controller } from "@hotwired/stimulus"
import { show } from "../helpers/hide"

export default class extends Controller {
  static targets = [
    "kind",
    "companyName",
    "countryCode",
    "vin",
    "tin",
    "tinLabelIndividual",
    "tinLabelCompany",
  ]

  connect() {
    this.update()
  }

  update() {
    show(this.tinLabelIndividualTarget, this.#isIndividual())
    show(this.tinLabelCompanyTarget, this.#isCompany())

    // Updates here should also:
    // - be applied to the component as hidden defaults
    // - match validations in the LegalEntity model
    show(this.companyNameTarget, this.#isCompany())
    show(this.vinTarget, this.#isCompany() && this.#isEuropean())
    show(this.tinTarget, this.#isCompany() || this.#isEuropean())
  }

  #isCompany() {
    return this.kindTarget.value == "company"
  }

  #isIndividual() {
    return this.kindTarget.value == "individual"
  }

  #isEuropean() {
    const country = this.countryCodeTarget.selectedOptions[0]
    return country?.dataset?.legalEntityEuropean == "true"
  }
}
